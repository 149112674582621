<template>
  <v-form
    ref="form"
    v-model="formModel"
    @submit.prevent="addProductToPriceListProposal"
  >
    <v-card class="insert-price-list-proposal-card">
      <v-card-title>
        {{ $t("priceList.insertPriceListProposal.title") }}
        <template v-if="replacedPriceListId">{{
          $t("priceList.insertPriceListProposal.replacedPriceListName", {
            replacedPriceListName: replacedPriceListName
          })
        }}</template>
      </v-card-title>

      <v-card-text v-if="negotiable">
        <v-select
          :items="warehouseList"
          item-text="name"
          item-value="warehouseId"
          v-model="warehouseListModel"
          :label="$t('priceList.insertPriceListProposal.warehouseList')"
          dense
          filled
          readonly
          outlined
          hide-details
          :rules="requiredRules"
        />
        <div class="d-flex justify-end" v-if="warehouseEditable">
          <v-btn
            text
            color="primary"
            class="change-address-btn mb-3"
            @click="changeWarehouseClicked"
          >
            {{ $t("priceList.insertPriceListProposal.changeWhBtn")
            }}{{ result }}
          </v-btn>
        </div>

        <!-- <template
          v-if="
            !replacedPriceListId && priceListList && priceListList.length > 1
          "
        >
           @change="fetchSuggestedPriceListProposalWarehouse" //comment
          <v-select
            :items="priceListList"
            :item-text="priceListNameAndDate"
            item-value="priceListId"
            v-model="priceListIdModel"
            :label="$t('priceList.insertPriceListProposal.priceListTemplate')"
            dense
            outlined
            :clearable="true"
          />
        </template>-->
        <span class="d-none" v-if="loaded">
          <v-input type="hidden" v-model="priceListIdModel"></v-input>
        </span>

        <!-- BEGIN DATE INIT -->
        <v-menu
          ref="menu"
          v-model="menuFromDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromDatePickerModel"
              :label="$t('priceList.insertPriceListProposal.fromDate')"
              :rules="dateRules"
              class="mt-1"
              required
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              append-icon="$calendar"
              @click:append="menuFromDate = true"
              readonly
            >
            </v-text-field>
          </template>
          <v-date-picker
            class="w-100"
            locale="it-IT"
            ref="picker"
            color="primary"
            v-model="selectedDateFromDate"
            :min="
              $dayjs()
                .add(1, 'day')
                .format('YYYY-MM-DD')
            "
            :show-current="
              $dayjs()
                .add(1, 'day')
                .format('YYYY-MM-DD')
            "
            first-day-of-week="1"
            type="date"
            no-title
            @input="handleDateChangeFromDate"
          ></v-date-picker>
        </v-menu>
        <!-- BEGIN DATE END -->
        <!-- END DATE MENU INIT -->
        <v-menu
          ref="menu"
          v-model="menuToDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="toDatePickerModel"
              :label="$t('priceList.insertPriceListProposal.toDate')"
              :rules="dateRules"
              class="mt-1"
              required
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              append-icon="$calendar"
              @click:append="menuToDate = true"
              readonly
            >
            </v-text-field>
          </template>
          <v-date-picker
            class="w-100"
            locale="it-IT"
            ref="picker"
            color="primary"
            v-model="selectedDateToDate"
            :min="
              $dayjs()
                .add(1, 'day')
                .format('YYYY-MM-DD')
            "
            :show-current="
              $dayjs()
                .add(1, 'day')
                .format('YYYY-MM-DD')
            "
            first-day-of-week="1"
            type="date"
            no-title
            @input="handleDateChangeToDate"
          ></v-date-picker>
        </v-menu>
        <!-- END MENU END -->
        <!--
        <v-checkbox
          v-model="clonePricesModel"
          class="clone-prices-checkbox"
          color="primary"
          required
          :label="$t('priceList.insertPriceListProposal.clonePrices')"
          :false-value="false"
          :true-value="true"
        />-->
      </v-card-text>
      <v-card-text v-else>
        {{ $t("priceList.insertPriceListProposal.nonegotiable") }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined depressed @click="abort">
          {{ $t("common.abort") }}
        </v-btn>
        <v-btn
          type="submit"
          v-if="negotiable"
          :disabled="loading"
          depressed
          color="primary"
        >
          {{ $t("priceList.insertPriceListProposal.submit") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<style lang="scss">
.insert-price-list-proposal-card {
  .v-picker.v-card.v-picker--date {
    width: 100%;
  }
}
</style>
<script>
import priceListService from "~/service/priceListService";
import priceListProposalService from "~/service/priceListProposalService";

import { mapGetters, mapState } from "vuex";
import { requiredValue } from "~/validator/validationRules";

export default {
  name: "InsertPriceListProposal",
  props: {
    replacedPriceListId: { type: Number, required: false },
    replacedPriceListName: { type: String, required: false },
    priceList: { type: Object, required: false }
  },
  data() {
    return {
      errorMessage: {},
      formModel: undefined,
      loaded: false,
      warehouseList: [],
      warehouseListModel: undefined,
      priceListList: undefined,
      negotiable: undefined,
      warehouseEditable: undefined,
      priceListIdModel: undefined,
      priceListNameModel: undefined,
      menuFromDate: false,
      menuToDate: false,
      selectedDateFromDate: null,
      selectedDateToDate: null,
      // clonePricesModel: false,
      loading: false,
      dateRules: [requiredValue("Inserire una data")],
      requiredRules: [requiredValue()]
    };
  },
  computed: {
    ...mapGetters({
      getCartWarehouse: "cart/getWarehouse",
      isSsoUser: "cart/isSsoUser"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    fromDatePickerModel: {
      get: function() {
        return this.selectedDateFromDate
          ? this.$dayjs(this.selectedDateFromDate).format("DD-MM-YYYY")
          : "";
      },
      set: function(value) {
        this.selectedDateFromDate = this.$dayjs(value, "DD-MM-YYYY").format(
          "DD-MM-YYYY"
        );
      }
    },
    toDatePickerModel: {
      get: function() {
        return this.selectedDateToDate
          ? this.$dayjs(this.selectedDateToDate).format("DD-MM-YYYY")
          : "";
      },
      set: function(value) {
        this.selectedDateToDate = this.$dayjs(value, "DD-MM-YYYY").format(
          "DD-MM-YYYY"
        );
      }
    }
  },
  methods: {
    async fetchPriceListList() {
      try {
        const result = await priceListService.getListToClone();
        if (result.response?.status == 0 && result.data?.priceList) {
          this.priceListList = result.data.priceList;
        } else if (result.response) {
          this.errorMessage = result.response;
        }
        //if (result.data.negotiable) this.negotiable = result.data.negotiable;
        if (result.data.warehouseEditable)
          this.changeWarehouse = result.data.warehouseEditable;
      } catch (err) {
        console.log(err);
      } finally {
        this.loaded = true;
      }
    },
    getWarehouseFromCart() {
      //COD_INT PRICE LIST
      //888 nostro listino
      ///999 mersy
      if (
        this.priceList &&
        this.priceList.codInt.includes("999") &&
        !this.cart.shippingAddress.autoAssigned
      ) {
        //this if is for mersy price list in modify price list
        this.warehouseList = [this.getCartWarehouse];
        this.warehouseListModel = this.warehouseList[0].warehouseId;
        return;
      }
      if (
        this.getCartWarehouse &&
        this.getCartWarehouse.warehouseId &&
        (this.cart.shippingAddress.autoAssigned != true ||
          !this.warehouseEditable)
      ) {
        this.warehouseList = [this.getCartWarehouse];
        this.warehouseListModel = this.warehouseList[0].warehouseId;
      }
    },
    async fetchPriceListProposalWarehouseList() {
      const result = await priceListProposalService.getWarehouseList();
      if (
        result &&
        result.data &&
        result.data.warehouses &&
        result.data.warehouses.length > 0
      ) {
        this.warehouseList = result.data.warehouses;
        this.warehouseListModel = result.data.warehouses[0].warehouseId;
      } else {
        this.warehouseList = [];
      }
    },
    async fetchSuggestedPriceListProposalWarehouse(priceListId) {
      const result = await priceListProposalService.getSuggestedWarehouseList(
        priceListId ? priceListId : this.priceListIdModel
      );
      if (
        result &&
        result.data &&
        result.data.warehouses &&
        result.data.warehouses.length > 0
      ) {
        this.warehouseListModel = result.data.warehouses[0].warehouseId;
      } else {
        this.warehouseListModel = undefined;
      }
    },
    async addProductToPriceListProposal() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        const insertResult = await priceListProposalService.insert(
          this.priceListNameModel, //name
          this.priceListIdModel, //priceListId
          this.replacedPriceListId, //replacedPriceListId
          this.$dayjs(this.fromDatePickerModel, "DD-MM-YYYY").format(
            "YYYY-MM-DD"
          ), //fromDate
          this.$dayjs(this.toDatePickerModel, "DD-MM-YYYY").format(
            "YYYY-MM-DD"
          ), //toDate
          this.warehouseListModel //whID
        );
        if (insertResult.response.status === 0) {
          this.$emit("submit", insertResult.data);
        } else this.loading = false;
      }
    },
    abort() {
      this.$emit("submit", false);
    },
    changeWarehouseClicked() {
      this.$emit("submit", "changeWharehouse");
    },
    priceListNameAndDate(item) {
      return `Listino #${item.priceListId} da ${this.$dayjs(
        item.fromDate
      ).format("DD-MM-YYYY")} a ${this.$dayjs(item.toDate).format(
        "DD-MM-YYYY"
      )} `;
    },
    handleDateChangeFromDate(date) {
      this.selectedDateFromDate = date;
      this.menuFromDate = false;
    },
    handleDateChangeToDate(date) {
      this.selectedDateToDate = date;
      this.menuToDate = false;
    }
  },
  async mounted() {
    /* if (!this.replacedPriceListId) {
      this.fetchPriceListList();
      // this.fetchPriceListProposalWarehouseList();
    } else {
      this.fetchPriceListList();
      // this.clonePricesModel = true;
      // await this.fetchPriceListProposalWarehouseList();
      //this.fetchSuggestedPriceListProposalWarehouse(this.replacedPriceListId);
    }*/
    //this.fetchPriceListList();
    if (this.cart.shippingAddress) {
      if (
        (this.cart.shippingAddress.addressTypeId === 102 &&
          this.cart.shippingAddress.zoneId > 0) ||
        (this.isSsoUser.roles.some(role => role.toLowerCase() === "salesman") &&
          this.cart.shippingAddress.deliveryServiceId != 2)
      )
        this.negotiable = false;
      else this.negotiable = true;
    } else this.negotiable = false;
    this.warehouseEditable = !this.isSsoUser.roles.some(
      role => role.toLowerCase() === "salesman"
    );
    this.getWarehouseFromCart();
  }
};
</script>
