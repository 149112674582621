import axios from "~/plugins/axios";

export default {
  getListPersonal() {
    return axios
      .get("/ebsn/api/price-list/list-personal")
      .then(data => data.data)
      .catch(error => error);
  },
  getListToClone() {
    return axios
      .get("/ebsn/api/price-list/list-to-clone")
      .then(data => data.data)
      .catch(error => error);
  },
  getDetail(priceListId) {
    let params = {
      price_list_id: priceListId
    };
    return axios
      .get("/ebsn/api/price-list/view", {
        params: params
      })
      .then(data => data.data)
      .catch(error => error);
  },
  getProductPrices(priceListId, page = 1, pageSize = 50) {
    let params = {
      price_list_id: priceListId,
      page: page,
      page_size: pageSize
    };
    return axios
      .get("/ebsn/api/price-list/list-product-prices", {
        params: params
      })
      .then(data => data.data)
      .catch(error => error);
  },
  close(params) {
    return axios
      .post("/ebsn/api/price-list/close", params, null)
      .then(data => data.data)
      .catch(error => error);
  },
  deletePriceList(params) {
    return axios
      .post("/ebsn/api/price-list/delete", params, null)
      .then(data => data.data)
      .catch(error => error);
  }
};
